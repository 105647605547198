'use client'

import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import { UseQueryResult } from '@tanstack/react-query'

export const useErrorToast = (query: Pick<UseQueryResult, 'error' | 'isError'>) => {
  const toast = useToast()

  useEffect(() => {
    if (query.error) {
      toast({ description: 'Something went wrong', status: 'error', position: 'top', duration: 3000 })
    }
  }, [query])
}
